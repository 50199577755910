import NextLink from "next/link";
import { Button } from "../components/general/Button";
import dlAppStore from "../assets/download_appstore.svg";
import dlPlayStore from "../assets/download_play.png";
import headerImage from "../assets/tennis-pla.png";
import Image from "next/image";
import PageWidthLayout from "../components/layout/PageWidthLayout";
import { useTranslation } from "next-i18next";
import { GetStaticProps } from "next";
import { serverSideTranslations } from "next-i18next/serverSideTranslations";
import mockup1 from "../assets/mockup1.png";
import mockup2 from "../assets/mockup2.png";
import mockup3 from "../assets/mockup3.png";
import mockup4 from "../assets/mockup4.png";
import tdt from "../assets/tennis_dolenjske_toplice.jpeg";
import AppFeature from "../components/marketing/AppFeature";
import {
  ArrowRightCircleIcon,
  ChevronRightIcon,
} from "@heroicons/react/20/solid";
import Head from "next/head";
import { useUser } from "@clerk/nextjs";

export const getStaticProps: GetStaticProps<any> = async ({ locale }) => ({
  props: {
    ...(await serverSideTranslations(locale ?? "en", ["common", "marketing"])),
  },
});

export default function HomePage() {
  const { t } = useTranslation(["marketing"]);
  const { user }= useUser();

  const features = [
    {
      image: mockup1.src,
      // title: "Discover",
      // description: "Finding courts near your location has never been easier. Just select your location and let the app do the rest. You will be booking courts in no time whether you are on the go or at home."
      title: t("discover"),
      description: t("discover-description"),
    },
    {
      image: mockup2.src,
      // title: "Book",
      // description: "Using intuitive and easy to use interface, you can book courts in no time. You can also book courts for your friends and family."
      title: t("book"),
      description: t("book-description"),
    },
    {
      image: mockup3.src,
      // title: "Play",
      // description: "Invite your friends, save match data and share your experience with the community."
      title: t("play"),
      description: t("play-description"),
    },
    {
      image: mockup4.src,
      // title: "Stay on top",
      // description: "Review your past bookings, upcoming bookings and your favourite courts. Communicate with friends and stay up to date with important notifications."
      title: t("stay-on-top"),
      description: t("stay-on-top-description"),
    },
  ];

  return (
    <>
      <Head>
        <title key="title">
          {t("Online tennis court booking platform")} - Courtbase
        </title>
      </Head>
      <div className="flex min-h-screen flex-col justify-between">
        <PageWidthLayout>
          <div>
            <div>
              <div className="grid px-4 md:grid-cols-2">
                <div className="flex flex-col justify-center">
                  <h1 className="mb-8 font-title text-5xl">
                    {t("tennis-courts")}. <br />
                    {t("online")}.
                  </h1>
                  <div className="flex items-center space-x-2">
                    <a href="https://apps.apple.com/us/app/courtbase/id6445848369">
                      <Image
                        src={dlAppStore.src}
                        height={dlAppStore.height}
                        width={120}
                        alt="Download Courtbase on App store"
                      />
                    </a>
                    <a href="https://play.google.com/store/apps/details?id=io.gn1.courtbase">
                      <Image
                        src={dlPlayStore.src}
                        height={dlPlayStore.height}
                        width={150}
                        alt="Download Courtbase on Play store"
                      />
                    </a>
                  </div>
                  <div className="before:content-[' '] relative my-3 flex justify-center before:absolute before:top-1/2 before:left-0 before:h-0.5 before:w-10 before:w-full before:bg-primary-500">
                    <span className="relative bg-white px-2">ali</span>
                  </div>
                  <div>
                    <NextLink href="/courts">
                      <Button label={t("view-courts") as string} />
                    </NextLink>
                  </div>
                </div>
                <div className="flex flex-col items-center justify-center">
                  <Image
                    src={headerImage.src}
                    width="500"
                    height="300"
                    alt="Courtbase header"
                  />
                </div>
              </div>
            </div>
            <div className="mb-20 mt-8">
              <NextLink
                href="/courts/tenis-dolenjske-toplice"
                className="mx-auto block transition hover:scale-105 md:w-2/3"
              >
                <div className="relative flex overflow-hidden rounded-lg bg-gradient-to-bl from-primary-500/20 to-primary-500/50 shadow-lg">
                  <Image
                    src={tdt.src}
                    alt="Tennis center Dolenjske Toplice"
                    width={150}
                    height={60}
                    className="mix-blend-hard-light"
                  />
                  <div className="flex items-center justify-between p-4">
                    <div>
                      <span className="font-title text-xl">
                        {t("featured")}:
                      </span>
                      <br />
                      <span className="text-gray-700">
                        Tenis center Dolenjske Toplice
                      </span>
                    </div>
                    <ArrowRightCircleIcon className="absolute top-1/2 right-3 h-7 w-7 -translate-y-1/2 transform text-gray-700" />
                  </div>
                </div>
              </NextLink>
            </div>
            <div className="space-y-20 py-16">
              {features.map((feature, index) => (
                <AppFeature
                  key={index}
                  {...feature}
                  reverse={index % 2 !== 0}
                />
              ))}
            </div>
          </div>
        </PageWidthLayout>
        <div className="mt-auto py-5 px-4">
          <p className="text-center text-gray-500">
            {t("contact-more-info")}?{" "}
            <a
              className="font-medium text-primary-500 underline"
              href="mailto:joze@gn1.io"
            >
              {t("contact-us")}
            </a>
          </p>
        </div>
      </div>
    </>
  );
}
