import classNames from "classnames";

interface ButtonProps extends React.ButtonHTMLAttributes<any> {
  isLoading?: boolean;
  label?: string;
  icon?: JSX.Element;
  size?: "xs" | "sm" | "md" | "lg";
  color?: 'primary' | 'danger'
}
export const Button = ({
  isLoading,
  label,
  icon,
  className,
  size,
  color,
  ...rest
}: ButtonProps) => {
  return (
    <button
      type="button"
      {...rest}
      className={classNames(
        "inline-flex items-center rounded-md border border-transparent text-white shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2",
        size === "xs" && "leading-2 px-2 py-1 text-xs font-medium",
        size === "sm" && "px-3 py-2 text-sm font-medium leading-4",
        (!size || size === "md") && "px-4 py-2 text-sm font-medium",
        size === "lg" && "px-4 py-2 text-base font-medium ",
        color === 'danger' && 'bg-red-400 hover:bg-red-400/90 focus:ring-red-400',
        (!color || color === 'primary') && 'bg-primary-500 hover:bg-primary-500/90 focus:ring-primary-500',
        className,
        rest.disabled && "bg-gray-300 hover:bg-gray-300",
      )}
    >
      {isLoading && (
        <svg
          className={classNames(
            "-ml-1 mr-3 animate-spin text-white",
            size === "xs" && "h-2 w-2",
            size === "sm" && "h-3 w-3",
            (size === "md" || !size) && "h-4 w-4 ",
            size === "lg" && "h-5 w-5",
          )}
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
        >
          <circle
            className="opacity-25"
            cx="12"
            cy="12"
            r="10"
            stroke="currentColor"
            strokeWidth="4"
          ></circle>
          <path
            className="opacity-75"
            fill="currentColor"
            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
          ></path>
        </svg>
      )}
      {icon && !isLoading && icon}
      {label}
    </button>
  );
};
