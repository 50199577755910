import Image from "next/image";
import classNames from "classnames";

interface AppFeatureProps {
  title: string;
  description: string;
  image: string;

  reverse?: boolean;
}
export default function AppFeature(props: AppFeatureProps) {
  return (
    <div className="grid md:grid-cols-2">
      <div className={classNames('flex items-center justify-center pt-14 md:pt-0', !props.reverse ? 'order-2 md:order-1' : 'order-1')}>
        <div className="relative before:absolute before:-left-2 md:before:-left-5 before:-top-2 md:before:-top-5 before:block before:h-full before:w-full before:rounded-3xl before:bg-gradient-to-tl before:from-primary-500 before:to-primary-500/20 before:shadow-xl before:content-['']">
          <Image src={props.image} height={200} width={200} alt="Discover Courtbase" className="z-10 relative" />
        </div>
      </div>
      <div className={classNames('flex flex-col justify-center', !props.reverse && 'md:order-2')}>
        <p className="mb-5 font-title text-3xl">{props.title}</p>
        <p className="text-lg text-gray-600">
          {props.description}
        </p>
      </div>
    </div>
  );
}
